import "./bootstrap.min.css";
import "./main.css";
import Nav from "../Nav";
import Form from "../pages/Form";
import Table from "../pages/Table";
import NotFound from "../pages/NotFound";
import Edit from "../pages/Edit";
import {HashRouter as Router, Routes, Route} from "react-router-dom"; // HashRouter BrowserRouter

const App = () => {
    const body = document.querySelector('body');
    const title = document.querySelector('title');

    return (
        <Router>
            <Nav />
            <Routes>
                <Route path="/" element={<Form body={body} title={title} />} />
                <Route path="/table/" element={<Table body={body} title={title} />} />
                <Route path="/edit/:id" element={<Edit title={title} body={body} />} />
                <Route path="*" element={<NotFound title={title} />} />
            </Routes>
        </Router>

    );
}

export default App;