import {Link} from "react-router-dom";
import {useContext} from "react";
import {TableContext} from "./index";
import loader from "../../../images/loader.gif";

const Tbody = () => {

    const {data, isLoading, error, status, badge, courses, newData} = useContext(TableContext);
    let requests = !newData ? data : newData

    return (
        <tbody id="tbody">
        {isLoading && <tr className="loader"><td><img src={loader}  alt="img" /></td></tr>}
        {error && <tr><td colSpan="8" className="_error">{error}</td></tr>}

        {requests && requests.map((request) => (
            <tr key={request.id}>
                <th scope="row">{request.id}</th>
                <td>{request.date}</td>
                <td>{courses[request.product]}</td>
                <td>{request.name}</td>
                <td>{request.email}</td>
                <td>{request.phone}</td>
                <td>
                    <div className={`badge badge-pill badge-${badge[request.status]}`}>
                        {status[request.status]}
                    </div>
                </td>
                <td><Link to={`/edit/${request.id}`}>Редактировать</Link></td>
            </tr>
        ))}
        </tbody>
    );
}

export default Tbody;