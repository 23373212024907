import Table from "./Table";
import TableFormFilter from "./TableFormFilter";

const MainWrapper = () => {
    return (
        <div className="main-wrapper">
            <div className="container-fluid">
                <div className="admin-heading-1">Все заявки</div>
                <TableFormFilter />
                <Table />
            </div>
        </div>
    );
}

export default MainWrapper;