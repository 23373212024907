const ExampleItem = function (name, phone, email, product){
    this.name = name;
    this.phone = phone;
    this.email = email;
    this.product = product;
}

const testData = [
    new ExampleItem('Сергей Васильевич', '+7954254625', 'droep@com.ru', 'courseHtml'),
    new ExampleItem('Алексей Дрозд', '+7544256789', 'lofgp@rt.ru', 'courseHtml'),
    new ExampleItem('Марина', '8544211111', 'tron@klop.ru', 'courseJs'),
    new ExampleItem('Петр Григорьев', '+7534961115', 'grigor@clot.ru', 'courseJs'),
    new ExampleItem('Сергей', '8555956145', 'cergeq@lofre.ru', 'courseVue'),
    new ExampleItem('Екатерина Палтус', '82344551345', 'paltys-re@polo.ru', 'courseVue'),
    new ExampleItem('Кирил Орехов', '+7884544666', 'keruxa-rer@ttt.ru', 'coursePhp'),
    new ExampleItem('Сусликов Сергеев', '+7985554876', 'syslik@mail.ru', 'coursePhp'),
    new ExampleItem('Иван', '+7852945638', 'newriol@mail.ru', 'courseWordpress'),
    new ExampleItem('Генадий', '+75484449922', 'gena@mail.ru', 'courseWordpress'),
];

// Рандомный выбор
function getRandomInt(max){
    return Math.floor(Math.random() * max);
}

// экспортируем данные
export default function formRandomTestData()
{
    const random = getRandomInt(testData.length);
    return testData[random];
}