import FormWrapper from "./FormWrapper";

const Form = ({body, title}) => {
    body.classList.add('radial-bg', 'flex-center');
    title.innerText = '01 - Форма'

    return (
        <div className="white-plate white-plate--payment">
            <div className="container-fluid">
                <div className="white-plate__header text-center">
                    <p className="white-plate__logo"><span>Форма</span> заявок</p>
                </div>
                <div className="white-plate__line-between white-plate__line-between--main"></div>
                <FormWrapper />
            </div>
        </div>
    );
}

export default Form;