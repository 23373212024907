import {Link} from "react-router-dom";

const NotFound = ({title}) => {

    title.innerText = '404 - Такой страницы не существует'

    return (
        <div className="container">
            <div className="row">
                <div className="col">
                    <div className="not-found">
                        <h2>Sorry</h2>
                        <p>That page cannot be found</p>
                        <Link to="/table/">Go to Homepaje</Link>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default NotFound;