import LeftPanel from "./LeftPanel";
import MainWrapper from "./MainWrapper";
import {createContext, useEffect, useState} from "react";
import useFetch from "../../../useFetch";
import {serverPath} from "../../../helpers/variables";
import listData from "../../../helpers/listData";

export const TableContext = createContext(null);

const Table = ({body, title}) => {

    useEffect(() => {
        body.classList.remove('radial-bg', 'flex-center');
        body.classList.add('body--dashboard');
        title.innerText = '02 - Таблица с заявками';
    },[])

    const {data, isLoading, error} = useFetch(serverPath + 'requests');
    const {status, badge, courses} = listData();

    const [product, setProduct] = useState('all');
    const [statusBar, setStatusBar] = useState('all');

    function filterProduct(filter, status) {
        let filteredRequests;

        if (filter !== 'all') {
            filteredRequests = data.filter((request) => request.product === filter)
        } else {
            filteredRequests = data;
        }

        if (status !== 'all') {
            filteredRequests = filteredRequests.filter((request) => request.status === status)
        }

        return filteredRequests;
    }
    const newData = filterProduct(product, statusBar);

    function counterRequestsNew() {
        const requestsNew = data && data.filter((request) => request.status === 'new');
        return requestsNew && requestsNew.length;
    }
    const numberNewRequests = counterRequestsNew();

    function updateTopStatusBar(status) {
        const links = document.querySelectorAll('a');

        links.forEach((link) => {
            link.classList.remove('active')
            if (link.dataset.value === status) {
                link.classList.add('active');
            }
        })
    }

    updateTopStatusBar(statusBar);

    return (
        <div>
            <TableContext.Provider
                value={{
                    data, isLoading, error,
                    status, badge, courses, product,
                    setProduct, statusBar, setStatusBar, newData, numberNewRequests}}>
                <LeftPanel />
                <MainWrapper />
            </TableContext.Provider>
        </div>
    );
}

export default Table;