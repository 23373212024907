import formRandomTestData from "./formRandomTestData";
import {useState} from "react";
import {serverPath} from "../../../helpers/variables";
import loader from "../../../images/loader.gif"
// import {useNavigate} from 'react-router-dom'; // потом задействую для редиректа

const FormWrapper = () => {

    let randomData = formRandomTestData();
    const date = new Intl.DateTimeFormat().format(new Date());
    const status = 'new';

    const [name, setName] = useState(randomData.name);
    const [phone, setPhone] = useState(randomData.phone);
    const [email, setEmail] = useState(randomData.email);
    const [product, setProduct] = useState(randomData.product);
    const [isPending, setIsPending] = useState(false);
    // const navigate = useNavigate();

    const handlerSubmit = (e) => {
        e.preventDefault();
        const request = {name, phone, email, product, date, status};
        setIsPending(true);

        const updateForm = () => {
            randomData = formRandomTestData();
            setName(randomData.name);
            setPhone(randomData.phone);
            setEmail(randomData.email);
            setProduct(randomData.product);
        }

        fetch(serverPath + 'requests', {
            method: "POST",
            headers: {"Content-type": "application/json"},
            body: JSON.stringify(request)
        }).then(() => {
            setIsPending(false);
            updateForm();
            // navigate('/table/')
        })

    }

    return (
        <form id="form" className="dws_form" onSubmit={handlerSubmit}>
            {isPending && <div className="loader"><img src={loader} /></div>}
            <label>Ваши данные:</label>
            <div className="form-group">
                <input id="name"
                       type="text"
                       name="name" autoComplete="on"
                       className="form-control"
                       placeholder="Имя и Фамилия"
                       value={name}
                       onChange={(e) => {setName(e.target.value)}}
                       required
                />
            </div>
            <div className="form-group">
                <input id="phone"
                       type="text"
                       name="phone"
                       autoComplete="on"
                       className="form-control"
                       placeholder="Телефон"
                       value={phone}
                       onChange={(e) => {setPhone(e.target.value)}}
                />
            </div>
            <div className="form-group">
                <input id="email"
                       type="email"
                       name="email"
                       autoComplete="on"
                       className="form-control"
                       placeholder="Email"
                       required
                       value={email}
                       onChange={(e) => {setEmail(e.target.value)}}

                />
            </div>
            <div className="form-group">
                <label htmlFor="exampleFormControlSelect1">Продукт:</label>
                <select id="product"
                        name="product"
                        className="form-control"
                        value={product}
                        onChange={(e) => {setProduct(e.target.value)}}
                >
                    <option value="courseHtml">Курс по верстке</option>
                    <option value="courseJs">Курс по JavaScript</option>
                    <option value="courseVue">Курс по VUE JS</option>
                    <option value="coursePhp">Курс по PHP</option>
                    <option value="courseWordpress">Курс по WordPress</option>
                </select>
            </div>
            <div className="form-group">
                {isPending && <button type="submit" className="btn btn-lg btn-primary btn-block" disabled>Заявка отправляется...</button>}
                {!isPending && <button type="submit" className="btn btn-lg btn-primary btn-block">Оформить заявку</button>}
            </div>
        </form>
    );
}

export default FormWrapper;