import icon from "./img/avatars/avatar.jpeg";
import {useContext} from "react";
import {TableContext} from "./index";
import {Link} from "react-router-dom";

const LeftPanel = () => {

    const {setStatusBar, numberNewRequests} = useContext(TableContext);

    return (
        <div className="left-panel blue-skin">
            <div className="left-panel__logo">
                <div className="left-panel__logo-title">CRM заявки</div>
                <div className="left-panel__logo-subtitle">учебный проект webcademy</div>
            </div>
            <div className="left-panel__user clearfix">
                <div className="left-panel__user-photo">
                    <img src={icon} alt="Avatar" />
                </div>
                <div className="left-panel__user-name">Горелов <br/>Денис</div>
            </div>
            <div className="left-panel__navigation">
                <div className="left-panel__navigation-title">Заявки</div>
                <ul>
                    <li>
                        <Link to="#"
                              data-value="all"
                              data-role="left-status"
                              className="active"
                              onClick={(e)=>{setStatusBar(e.target.dataset.value)}}
                        >Все вместе</Link>
                    </li>
                    <li>
                        <Link to="#"
                              data-value="new"
                              data-role="left-status"
                              onClick={(e)=>{setStatusBar(e.target.dataset.value)}}
                        >Новые<div className="badge" id="badge-new">{numberNewRequests}</div></Link>
                    </li>
                    <li>
                        <Link to="#"
                              data-value="inwork"
                              data-role="left-status"
                              onClick={(e)=>{setStatusBar(e.target.dataset.value)}}
                        >В работе</Link>
                    </li>
                    <li>
                        <Link to="#"
                              data-value="complete"
                              data-role="left-status"
                              onClick={(e)=>{setStatusBar(e.target.dataset.value)}}
                        >Завершенные</Link>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default LeftPanel;