const listData = () => {

    return ({
        status: {
            new: 'Новая',
            inwork: 'В работе',
            complete: 'Завершена'
        },
        badge: {
            new: 'danger',
            inwork: 'warning',
            complete: 'success'
        },
        courses: {
            courseHtml: 'Курс по верстке',
            courseJs: 'Курс по JS',
            courseVue: 'Курс по Vue',
            coursePhp: 'Курс по PHP',
            courseWordpress: 'Курс по Wordpress',
        }
    })
}

export default listData;