import loader from "../../../images/loader.gif";
import {useContext} from "react";
import {AppContext} from "./index";
import CardBody from "./CardBody";

const Card = ({delStatus, setDisabled}) => {
    const {request, isLoading, error} = useContext(AppContext);

    return (
        <div className="card mb-4">
            <div className="card-header">Данные о заявке</div>
            {isLoading && <div className="loader"><img src={loader} /></div>}
            {error && <div className="_error">{error}</div>}
            {delStatus && <div className="_error">Ошибка удаления</div>}
            {request && <CardBody setDisabled={setDisabled} />}
        </div>
    );
}

export default Card;