import {useParams} from "react-router-dom";
import useFetch from "../../../useFetch";
import {serverPath} from "../../../helpers/variables";
import Title from "./Title";
import RowForm from "./RowForm";
import {createContext} from "react";

export const AppContext = createContext(null);

const Edit = ({title, body}) => {
    const {id} = useParams();
    const {data: request, isLoading, error} = useFetch(serverPath + 'requests/' + id);

    title.innerText = '03 - Редактировать заявку';
    body.classList.remove('body--dashboard');

    return (
        <div className="form-wrapper">
            <div className="container-fluid">
                <AppContext.Provider value={{request, isLoading, error}}>
                    <Title />
                    <RowForm />
                </AppContext.Provider>
            </div>
        </div>
    );
}

export default Edit;