const BtnSubmit = ({deleteRequest, disabled}) => {
    return (
        <div className="row justify-content-between">
            <div className="col text-right">
                <button type="button" className="btn btn-primary" onClick={() => deleteRequest()}>Удалить заявку</button>
                <button type="submit" disabled={disabled} className="btn btn-primary">Сохранить изменения</button>
            </div>
        </div>
    );
}
export default BtnSubmit;