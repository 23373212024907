import {useContext, useState} from "react";
import {AppContext} from "./index";

const CardBody = ({setDisabled}) => {

    const {request} = useContext(AppContext);

    const {id, date} = request;
    const [name, setName] = useState(request.name);
    const [phone, setPhone] = useState(request.phone);
    const [product, setProduct] = useState(request.product);
    const [status, setStatus] = useState(request.status);
    const [email, setEmail] = useState(request.email);

    return (
        <div className="card-body">
            <div className="row mb-3">
                <div className="col-md-2"><strong>ID:</strong></div>
                <div className="col">Заявка №<span id="number">{id}</span></div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2"><strong>Дата создания:</strong></div>
                <div className="col" id="date">{date}</div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2"><strong>Продукт:</strong></div>
                <div className="col">
                    <select
                        onChange={(e) => {setProduct(e.target.value); setDisabled(false)}}
                        id="product"
                        name="product"
                        className="custom-select"
                        value={product && product}
                    >
                        <option value="courseHtml">Курс по верстке</option>
                        <option value="courseJs">Курс по JavaScript</option>
                        <option value="courseVue">Курс по VUE JS</option>
                        <option value="coursePhp">Курс по PHP</option>
                        <option value="courseWordpress">Курс по WordPress</option>
                    </select>
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2"><strong>Имя:</strong></div>
                <div className="col">
                    <input
                        onChange={(e) => {setName(e.target.value); setDisabled(false)}}
                        type="text"
                        className="form-control"
                        value={name}
                        id="name"
                        name="name"
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2"><strong>Email:</strong></div>
                <div className="col">
                    <input
                        onChange={(e) => {setEmail(e.target.value); setDisabled(false)}}
                        type="text"
                        className="form-control"
                        value={email}
                        id="email"
                        name="email"
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2"><strong>Телефон:</strong></div>
                <div className="col">
                    <input
                        onChange={(e) => {setPhone(e.target.value); setDisabled(false)}}
                        type="text"
                        className="form-control"
                        value={phone}
                        id="phone"
                        name="phone"
                    />
                </div>
            </div>
            <div className="row mb-3">
                <div className="col-md-2"><strong>Статус заявки:</strong></div>
                <div className="col">
                    <select
                        onChange={(e) => {setStatus(e.target.value); setDisabled(false)}}
                        className="custom-select"
                        id="status"
                        name="status"
                        value={status}
                    >
                        <option value="new">Новая</option>
                        <option value="inwork">В работе</option>
                        <option value="complete">Завершена</option>
                    </select>
                </div>
            </div>
        </div>
    );
}

export default CardBody;