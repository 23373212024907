import {useContext} from "react";
import {TableContext} from "./index";
import {Link} from "react-router-dom";

const TableFormFilter = () => {

    const {product, setProduct, setStatusBar} = useContext(TableContext);

    return (
        <form>
            <div className="row mb-3 justify-content-start">
                <div className="col">
                    <div id="topStatusBar" className="btn-group" role="group" aria-label="...">
                        <Link to="#"
                              className="btn btn-light"
                              data-value="all"
                              onClick={(e) => {setStatusBar(e.target.dataset.value)}}
                        >Все</Link>
                        <Link to="#"
                              className="btn btn-light"
                              data-value="new"
                              onClick={(e) => {setStatusBar(e.target.dataset.value)}}
                        >Новые</Link>
                        <Link to="#"
                              className="btn btn-light"
                              data-value="inwork"
                              onClick={(e) => {setStatusBar(e.target.dataset.value)}}
                        >В работе</Link>
                        <Link to="#"
                              className="btn btn-light"
                              data-value="complete"
                              onClick={(e) => {setStatusBar(e.target.dataset.value)}}
                        >Завершенные</Link>
                    </div>
                </div>
                <div className="col">
                    <select
                        value={product}
                        onChange={(e) => {setProduct(e.target.value)}}
                        className="custom-select"
                        id="productSelect"
                    >
                        <option value="all">Все продукты</option>
                        <option value="courseHtml">Курс по верстке</option>
                        <option value="courseJs">Курс по JavaScript</option>
                        <option value="courseVue">Курс по VUE JS</option>
                        <option value="coursePhp">Курс по PHP</option>
                        <option value="courseWordpress">Курс по WordPress</option>
                    </select>
                </div>
            </div>
        </form>
    );
}

export default TableFormFilter;