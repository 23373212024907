// import EditForm from "./EditForm";
import Card from "./Card";
import BtnSubmit from "./BtnSubmit";
import {useContext, useState} from "react";
import {AppContext} from "./index";
import {serverPath} from "../../../helpers/variables";
import {useNavigate} from 'react-router-dom';

const RowForm = () => {

    const {request} = useContext(AppContext);
    const navigate = useNavigate();

    const [delStatus, setDelStatus] = useState(false);
    const [disabled, setDisabled] = useState(true);

    const updateFetch = (e) => {
        e.preventDefault();

        const data = {
            ...request,
            product: e.target.querySelector('#product').value,
            name: e.target.querySelector('#name').value,
            email: e.target.querySelector('#email').value,
            phone: e.target.querySelector('#phone').value,
            status: e.target.querySelector('#status').value
        }

        fetch( serverPath + 'requests/' + request.id, {
            method: 'PUT',
            headers: {'Content-Type' : 'application/json'},
            body: JSON.stringify(data)
        }).then((res) => {
            res.ok && navigate('/table/');
        })
    }

    const deleteRequest = () => {
        fetch( serverPath + 'requests/' + request.id, {
            method: 'DELETE',
        }).then((res) => {
            if (res.status === 404) {
                setDelStatus(true);
            } else {
                setDelStatus(false);
            }
            res.ok && navigate('/table/')
        })
    }

    return (
        <div className="row">
            <div className="col">
                <form id="form" onSubmit={updateFetch}>
                    <Card delStatus={delStatus} setDisabled={setDisabled} />
                    <BtnSubmit deleteRequest={deleteRequest} disabled={disabled} />
                </form>
            </div>
        </div>
    );
}

export default RowForm;