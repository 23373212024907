import Tbody from "./Tbody";

const Table = () => {
    return (
        <table className="table fs-14 dws_tbody">
            <thead>
                <tr>
                    <th>ID</th>
                    <th>дата</th>
                    <th>продукт</th>
                    <th>имя</th>
                    <th>email</th>
                    <th>телефон</th>
                    <th>статус</th>
                    <th></th>
                </tr>
            </thead>
            <Tbody />
        </table>
    );
}

export default Table;